import React, { useRef } from "react";
import "./App.css";
import Banner from "./components/banner";
import NavScrollExample from "./components/header";
import Section from "./components/section";
import aboutImg from "./images/about-us.jpg";
import visonImg from "./images/vision.jpg";
import donationImg from "./images/prerana-mission.jpg";
import qrCode from "./images/qr-code.jpg";
import addressimg from "./images/address-img.jpg";
import Footer from "./components/footer";
import FbSection from "./components/FbSection";

function App() {
  // Create refs for each section
  const aboutusRef = useRef(null);
  const visionRef = useRef(null);
  const donationsRef = useRef(null);
  const addressRef = useRef(null);
  const teamRef = useRef(null);
  const homeRef = useRef(null);

  // Function to scroll to the specific section
  const scrollToComponent = (componentRef) => {
    componentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Dynamic content for the sections
  const p1 =
    "Prerana Helping Society, ignited on August 15th, 2007, is a beacon of compassion led by dedicated students. Rooted in Tirupati, Andhra Pradesh, we've assionately organized monthly social service events, impacting over 12 NGOs. Students are the heartbeat of Prerana, with seniors guiding the engine but recognizing students as the primary source of energy.";
  const p2 =
    "Our journey involves uplifting lives through visits to orphanages and care homes, distributing essentials, and conducting relief efforts. Festivities like birthdays and special occasions are shared with those we serve. Committed to positive change, we host awareness campaigns and blood donation camps. As our Prerana family grows, we continue to embrace humanity, fueled by enduring wisdom, for a more compassionate society.";
  const list = [
    <p>
      <strong>17 Years of Service</strong>
    </p>,
    <p>
      <strong>Students</strong> from <strong>25+ Different Colleges</strong>{" "}
      involved
    </p>,
    <p>
      <strong>1,000+ Members and Volunteers</strong> served the society through
      Prerana
    </p>,
    <p>
      <strong>2,500+</strong> Blood Donations
    </p>,
    <p>
      <strong>500+</strong> Scribes
    </p>,
    <p>
      Prerana <strong>Funds</strong> used for the Events and Anniversary events
      - <strong>₹23,00,000+</strong>
    </p>,
  ];

  const v1 =
    "To lead our organization with utmost integrity and transparency to heal and transform the lives of the needy...";
  const v2 =
    "To pioneer the art of wisdom with unified humanity to be future-ready...";

  const d1 =
    "Join hands with the Prerana Helping Society in making a meaningful impact...";
  const d2 = "Here are the details to contribute to our cause: ";

  const accountname = "Prerana Helping Society";
  const accountnumer = "043301000019509";
  const ifsc = "IOBA0000433";
  const branch = "Tirupati Main Branch";
  const bank = "Indian Overseas Bank";

  const addressContent =
    "19-14-80, Raghavendra Nagar 3rd cross, Near Bairagi patteda...";
  const addressc2 =
    "Recognized under the Registration of Societies Act 35 of 2001, registration: 415/2007.";

  return (
    <div className="App">
      <NavScrollExample
        scrollToComponent={scrollToComponent}
        aboutusRef={aboutusRef}
        visionRef={visionRef}
        donationsRef={donationsRef}
        addressRef={addressRef}
        teamRef={teamRef}
        homeRef={homeRef}
      />
      <div ref={homeRef}>
        <Banner />
      </div>
      <div>
        {/* About Us Section */}
        <div ref={aboutusRef}>
          <Section
            hedding="About Us"
            p1={p1}
            p2={p2}
            listda={list}
            img1={aboutImg}
          />
        </div>

        {/* Vision & Mission Section */}
        <div ref={visionRef}>
          <Section
            hedding="Vision and Mission"
            s1="Vision"
            s2="Mission"
            v1={v1}
            v2={v2}
            img1={visonImg}
          />
        </div>

        <FbSection />

        {/* Donations Section */}
        <div ref={donationsRef}>
          <Section
            hedding="Donations"
            p1={d1}
            d1={d1}
            d2={d2}
            accountname={accountname}
            accountnumer={accountnumer}
            ifsc={ifsc}
            branch={branch}
            bank={bank}
            img1={donationImg}
            qrCode={qrCode}
            PHS="PHS@iob"
          />
        </div>

        {/* Address Section */}
        <div ref={addressRef}>
          <Section
            hedding="Address"
            s1="Registered Address:"
            v1={addressContent}
            v2={addressc2}
            img1={addressimg}
            fbLink="https://www.facebook.com/prerana.helpingsociety"
            instaLink="https://www.instagram.com/phs_team/"
            twitterLink="https://twitter.com/Prerana_PHS"
          />
        </div>
      </div>
      <div ref={teamRef}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
