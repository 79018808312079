import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../images/prerana-logo.png";

function NavScrollExample({
  scrollToComponent,
  aboutusRef,
  visionRef,
  donationsRef,
  addressRef,
  teamRef,
  homeRef
}) {
  const [expanded, setExpanded] = useState(false); // State to control Navbar collapse

  const handleNavClick = (ref) => {
    scrollToComponent(ref); // Scroll to the section
    setExpanded(false); // Collapse the Navbar after clicking a link
  };
  return (
    <Navbar
      expand="lg"
      className="bg-dark text-light sticky"
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand href="#" className="text-light">
          <img src={logo} className="logo-img" alt="Prerana Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          onClick={() => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0 text-light" navbarScroll>
          <Nav.Link
              href="#home"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                scrollToComponent(homeRef);
                setExpanded(expanded ? false : true);
              }}
            >
              Home
            </Nav.Link>
            {/* Scroll to About Us Section */}
            <Nav.Link
              href="#aboutus"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                scrollToComponent(aboutusRef);
                setExpanded(expanded ? false : true);
              }}
            >
              About us
            </Nav.Link>

            {/* Scroll to Vision & Mission Section */}
            <Nav.Link
              href="#vision"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                scrollToComponent(visionRef);
                setExpanded(expanded ? false : true);
              }}
            >
              Vision & Mission
            </Nav.Link>

            {/* Scroll to Donations Section */}
            <Nav.Link
              href="#donations"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                scrollToComponent(donationsRef);
                setExpanded(expanded ? false : true);
              }}
            >
              Donations
            </Nav.Link>

            {/* Scroll to Address Section */}
            <Nav.Link
              href="#address"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                scrollToComponent(addressRef);
                setExpanded(expanded ? false : true);
              }}
            >
              Address
            </Nav.Link>

            {/* Scroll to Our Team Section */}
            <Nav.Link
              href="#team"
              className="text-light"
              onClick={(e) => {
                e.preventDefault();
                scrollToComponent(teamRef);
                setExpanded(expanded ? false : true);
              }}
            >
              Our Team
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
