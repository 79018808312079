import { useEffect } from "react";
import "./FbSection.css"; // Optionally import styles if needed
import { InstagramEmbed, FacebookEmbed } from "react-social-media-embed";
const FbSection = () => {
  useEffect(() => {
    // Check if the Facebook SDK is loaded, if not wait for it to load
    if (!window.FB) {
      window.fbAsyncInit = () => {
        window.FB.init({
          xfbml: true,
          version: "v13.0",
        });
        window.FB.XFBML.parse();
      };
    } else {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div className="fb-container container">
      <div className="row">
        <div className="col-12">
          <h2 class="heading-sty socialM">Social Media</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div
            className="fb-page"
            data-href="https://www.facebook.com/prerana.helpingsociety"
            data-tabs="timeline"
            data-width="500" /* Set a fixed width here */
            data-height="500" /* Adjust height as needed */
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
          ></div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <InstagramEmbed url="https://www.instagram.com/phs_team" />
        </div>
      </div>
    </div>
  );
};

export default FbSection;
