import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import footerImg from '../images/prerana-footer.JPG'

const Footer = () => {
  return (
    <footer>
      <Container className='bg-sec'>
      <Row className='p-5'>
        <Col className='about-style pdin-y'>
            <h2 className='heading-sty'>Our Team</h2>
            <img src={footerImg} width="100%" height="auto"/>
        </Col>
      </Row>
      </Container>
    </footer>
  )
}

export default Footer