import React from 'react';
import '../index.css';

const Banner = () => {
  return (
    <header className='header'>
      
    </header>
  )
}

export default Banner
