import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import fb from "../images/facebook.png";
import insta from "../images/instagram.png";
import twtr from "../images/twitter.png";
const Section = ({
  hedding,
  p1,
  p2,
  listda,
  s1,
  s2,
  hedding1,
  v1,
  v2,
  img1,
  img2,
  d1,
  d2,
  accountname,
  accountnumer,
  ifsc,
  branch,
  bank,
  qrCode,
  PHS,
  fbLink,
  instaLink,
  twitterLink,
}) => {
  return (
    <Container className="bg-sec p-5">
      <Row>
        <Col xs={12} lg={6} className="about-style pdin-y">
          {hedding && <h2 className="heading-sty">{hedding}</h2>}
          {hedding1 && <h2 className="heading-sty">{hedding1}</h2>}
          {v1 && (
            <p className="para-sty pp-b">
              <strong>{s1}</strong> {v1}
            </p>
          )}
          <p className="para-sty">
            <strong>{s2}</strong> {v2}
          </p>
          {fbLink && instaLink && twitterLink ? (
            <div className="socialSec">
              {fbLink && (
                <a href={fbLink} className="sw" target="_blank">
                  <img src={fb} />
                </a>
              )}
              {instaLink && (
                <a href={instaLink} className="sw mx-3" target="_blank">
                  <img src={insta} />
                </a>
              )}
              {twitterLink && (
                <a href={twitterLink} className="sw" target="_blank">
                  <img src={twtr} />
                </a>
              )}
            </div>
          ) : null}

          {p1 && <p className="para-sty">{p1}</p>}
          {p2 && <p className="para-sty">{p2}</p>}
          {d2 && (
            <strong>
              <p className="para-sty">{d2}</p>
            </strong>
          )}
          {accountname && (
            <div>
              <strong>Account Name :</strong> {accountname}
            </div>
          )}
          {accountnumer && (
            <div>
              <strong>Account Numer :</strong> {accountnumer}
            </div>
          )}
          {ifsc && (
            <div>
              <strong>IFSC Code :</strong> {ifsc}
            </div>
          )}
          {branch && (
            <div>
              <strong>Branch :</strong> {branch}
            </div>
          )}
          {bank && (
            <div>
              <strong>Bank :</strong> {bank}
            </div>
          )}
          <br />
          {qrCode && (
            <h4>
              <strong>QR Code (UPI):</strong>
            </h4>
          )}
          {qrCode && <img src={qrCode} width={"50%"} height={"auto"} />}
          {PHS && (
            <h4>
              <strong>UPI ID: PHS@iob</strong>
            </h4>
          )}
        </Col>
        <Col xs={12} lg={6} className="bg-img-all">
          <img src={img1}></img>
        </Col>
      </Row>
      {listda && (
        <Row>
          <Col xs={12} className="para-sty">
            <div>
              {listda && (
                <ul style={{ padding: "20px" }}>
                  {listda.map((item) => (
                    <li className="ml-5">{item}</li>
                  ))}
                </ul>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Section;
